import { alpha } from '@mui/material';
import * as baseColors from '../colors';
import { Colors } from '../colors';

const { white } = baseColors;
const base = white;

const colors: Colors = {
  ...baseColors,

  base, // #FFFFFF

  text: {
    primary: alpha(base, 0.85), // #FFFFFF - 85%
    secondary: alpha(base, 0.65), // #FFFFFF - 65%
    disabled: alpha(base, 0.4), // #FFFFFF - 40%
  },

  helper: alpha(base, 0.5), // #FFFFFF - 50%

  icon: {
    high: alpha(base, 0.65), // #FFFFFF - 65%
    medium: alpha(base, 0.4), // #FFFFFF - 40%
    disabled: alpha(base, 0.25), // #FFFFFF - 25%
  },

  line: {
    high: alpha(base, 0.3), // #FFFFFF - 30%
    medium: alpha(base, 0.2), // #FFFFFF - 20%
    disabled: alpha(base, 0.1), // #FFFFFF - 10%
  },

  fill: {
    high: alpha(base, 0.85), // #FFFFFF - 85%
    medium: alpha(base, 0.12), // #FFFFFF - 12%
    disabled: alpha(base, 0.05), // #FFFFFF - 5%
  },

  background: '#131313',
  menu: '#333437',
  paper: '#18191A',
};

export default colors;
