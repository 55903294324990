import { useTheme } from '@mui/material';

import dark from '@/styles/themes/dark';
import light from '@/styles/themes/light';

interface UseColorModeOutput {
  isDarkMode: boolean;
  theme: typeof light | typeof dark;
}

export const useColorMode = (): UseColorModeOutput => {
  const {
    palette: { mode },
  } = useTheme();

  const isDarkMode = mode === 'dark';
  const theme = isDarkMode ? light : dark;

  return {
    isDarkMode,
    theme,
  };
};
