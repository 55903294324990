import { Component, ReactNode } from 'react';
import { WaldoApiError } from '@/ext/lib/api';
import { isWaldoInstalled } from '@/lib/utils';

export class AuthErrorBoundary extends Component<
  { fallback?: ReactNode },
  { hasError: boolean }
> {
  constructor(props: { fallback: ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: WaldoApiError) {
    if (error.status === 401) {
      const { pathname, search } = window.location;
      const omnibox = new URLSearchParams(search).get('omnibox');
      if (pathname === '/search' && omnibox === 'true' && !isWaldoInstalled()) {
        window.location.href = `https://www.google.com/search${search}`;
      }
    } else {
      throw error;
    }
  }

  render() {
    const { hasError } = this.state;
    const { children, fallback } = this.props;

    if (hasError) {
      return fallback || null;
    }
    return children;
  }
}
