export interface Colors {
  green: {
    main: string;
    lighter: string;
    light: string;
    dark: string;
    darker: string;
  };

  yellow: {
    main: string;
    dark: string;
  };

  magenta: {
    main: string;
    lighter: string;
    light: string;
    dark: string;
    darker: string;
  };

  text: {
    primary: string;
    secondary: string;
    disabled: string;
  };

  helper: string;

  icon: {
    high: string;
    medium: string;
    disabled: string;
  };

  line: {
    high: string;
    medium: string;
    disabled: string;
  };

  fill: {
    high: string;
    medium: string;
    disabled: string;
  };

  black: {
    main: string;
    lightest: string;
    lighter: string;
    light: string;
    dark: string;
    darker: string;
  };

  chrome: {
    main: string;
    light: string;
    dark: string;
    darker: string;
  };

  grey: {
    main: string;
    light: string;
    lighter: string;
    dark: string;
  };

  background: string;
  paper: string;
  menu: string;
  white: string;
  base: string;
}

export const white = '#FFFFFF';
export const black = {
  main: '#000000',
  lightest: '#ACACAC',
  lighter: '#8B8B8B',
  light: '#585858',
  dark: '#131313',
  darker: '#0F0F0F',
};
export const yellow = {
  main: '#FAC034',
  dark: '#E3AC38',
  lighter: '#FFEDC0',
  light: '#F7D371',
  darker: '#DE8D13',
};
export const magenta = {
  main: '#D40A60',
  dark: '#9A164E',
  light: '#F0428C',
  lighter: '#FDC9DF',
  darker: '#9A164E',
};
export const chrome = {
  main: '#D5E9E9',
  light: '#ECFBFB',
  dark: '#BDD8D8',
  darker: '#A7D3D3',
};
export const grey = {
  main: '#E0E0E0',
  lighter: '#FBFBFB',
  light: '#F7F7F7',
  dark: '#D0D0D0',
};
export const green = {
  main: '#64DFDF',
  lighter: '#DAFFFD',
  light: '#8CFAF5',
  dark: '#2DB4B4',
  darker: '#1B8C8C',
};

const baseColors: Partial<Colors> = {
  black,
  green,
  magenta,
  white,
  yellow,
  chrome,
  grey,
};

export default baseColors;
