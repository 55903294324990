import { FunctionComponent } from 'react';
import { Box, styled } from '@mui/material';

import ProfileMenu from '@/components/ProfileMenu';
import ThemeProvider, {
  ThemeProviderProps,
} from '@/components/providers/ThemeProvider';
import legacyTheme from '@/styles/themes/site-legacy/dark';
import SettingsMenu from '@/ext/app/layout/SettingsMenu';
import WhenMounted from '@/components/molecules/WhenMounted';

export interface DefaultTemplateProps extends ThemeProviderProps {
  container?: boolean;
  showProfileButton?: boolean;
  showSettingsMenu?: boolean;
  legacy?: boolean;
}

const ProfileButton = styled(ProfileMenu)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  zIndex: 1200,

  [theme.breakpoints.up('md')]: {
    right: theme.spacing(2),
  },
}));

const SettingsButton = styled(SettingsMenu)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  zIndex: 1200,

  [theme.breakpoints.up('md')]: {
    right: theme.spacing(2),
  },
}));

const DefaultTemplate: FunctionComponent<DefaultTemplateProps> = ({
  children,
  container,
  showProfileButton = true,
  showSettingsMenu = false,
  legacy,
  theme,
}) => (
  <ThemeProvider theme={legacy ? legacyTheme : theme}>
    <WhenMounted>
      {showSettingsMenu && <SettingsButton useStandaloneDialogs />}
      {!showSettingsMenu && showProfileButton && <ProfileButton />}
    </WhenMounted>
    {container ? <Box paddingX={1}>{children}</Box> : children}
  </ThemeProvider>
);

export default DefaultTemplate;
