import { alpha, createTheme, ThemeOptions } from '@mui/material';
import baseTheme from '..';
import colors from './colors';

const {
  background,
  base,
  black,
  fill,
  grey,
  helper,
  icon,
  line,
  paper,
  text,
  white,
} = colors;

const baseOptions: ThemeOptions = {
  palette: {
    mode: 'dark',

    background: {
      default: background,
      paper,
    },

    fill,
    helper,
    icon,
    line,
    text,

    disabled: text.disabled,
    divider: fill.disabled,

    // For buttons
    neutral: {
      contrastText: white,
      dark: fill.high,
      light: fill.medium,
      main: fill.high,
    },

    contrast: {
      contrastText: black.main,
      dark: base,
      light: fill.medium,
      main: fill.high,
    },
  },
};

const theme = createTheme(baseTheme, baseOptions);
const { palette } = theme;

export const overrides: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '::-webkit-scrollbar-thumb': {
          backgroundColor: alpha(palette.text.primary, 0.5),

          '&:hover': {
            backgroundColor: alpha(palette.text.primary, 0.6),
          },
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          color: grey.lighter,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          padding: '0.5rem',
          textTransform: 'none',
        },

        contained: {
          padding: '1rem',
          borderRadius: '0.5rem',

          '&.Mui-disabled': {
            color: black.light,
            borderColor: black.light,
            backgroundColor: 'inherit',
            cursor: 'not-allowed',
          },
        },

        outlined: {
          color: grey.lighter,
          borderColor: grey.lighter,
        },
      },
      defaultProps: {
        color: 'primary',
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-colorDefault': {
            '&:not(.MuiChip-outlined)': {
              backgroundColor: palette.fill.disabled,
            },

            '&:hover': {
              backgroundColor: palette.fill.medium,
            },
          },
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          color: palette.primary.dark,
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: palette.text.secondary,
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:disabled': {
            color: palette.icon?.disabled,
          },
        },
      },
    },
  },
};

export default createTheme(theme, overrides);
