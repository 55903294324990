import { createTheme, PaletteOptions, ThemeOptions } from '@mui/material';

import { black, chrome, green, grey, magenta, white } from './colors';

// For theming right:
// For light theme, we should always matching white color with palette.background.default and
// black with palette.text.primary

const paletteOptions: PaletteOptions = {
  primary: {
    main: green.main,
    light: green.lighter,
    dark: green.dark,
  },

  secondary: {
    main: magenta.main,
    light: magenta.light,
    dark: magenta.dark,
  },

  action: {
    hover: green.light,
    selected: green.main,
  },

  error: {
    main: magenta.main,
    light: magenta.light,
    dark: magenta.dark,
  },

  info: {
    main: chrome.main,
  },

  // success: {
  //   dark: greenDark,
  //   light: greenLight,
  //   main: green,
  // },

  text: {
    primary: grey.lighter,
    secondary: chrome.main,
  },

  background: {
    default: black.dark,
    paper: white,
  },

  divider: black.main,
};

const baseOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },

  palette: paletteOptions,

  shape: {
    borderRadius: 5,
  },

  spacing: 16,

  typography: {
    fontFamily: ['Inter', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),

    fontSize: 16,

    h1: {
      fontSize: 56,
      letterSpacing: '-0.05em',
      lineHeight: 1,
      fontWeight: 800,
    },

    h2: {
      fontSize: 48,
      letterSpacing: '-0.03em',
      // TODO: change this line height to match the mocks after the theme cards are done
      lineHeight: 1.5,
      fontWeight: 800,
    },

    h3: {
      fontSize: 32,
      letterSpacing: '-0.02em',
      lineHeight: 1.25,
      fontWeight: 800,
    },

    h4: {
      fontSize: 24,
      letterSpacing: '-0.02em',
      lineHeight: 1.333333333,
      fontWeight: 800,
    },

    h5: {
      fontSize: 24,
      letterSpacing: '-0.02em',
      lineHeight: 1.333333333,
      fontWeight: 500,
    },

    h6: {
      fontSize: 24,
      letterSpacing: '-0.02em',
      lineHeight: 1.333333333,
      fontWeight: 400,
    },

    body1: {
      fontSize: 18,
      lineHeight: 1.333333333,
      fontWeight: 400,
    },

    body2: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 400,
    },

    button: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1,
    },

    caption: {
      fontFamily: 'Fraktion Mono',
      fontSize: 16,
      letterSpacing: '-0.04em',
      lineHeight: 1.5,
      fontWeight: 400,
    },

    subtitle1: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 400,
    },

    subtitle2: {
      fontSize: 15,
      lineHeight: 1.6,
      fontWeight: 400,
    },
  },
};

const base = createTheme(baseOptions);

export const themeOptions: ThemeOptions = {
  // ... Overrides
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '0.5rem',
          textTransform: 'none',
        },

        contained: {
          padding: '1rem',
          border: '2px solid',
          borderRadius: '0.5rem',

          '&$disabled': {
            color: black.light,
            borderColor: black.light,
            backgroundColor: 'inherit',
            cursor: 'not-allowed',
          },
        },

        containedPrimary: {
          boxShadow: '1px 3px 0px #4A8481',
          borderColor: green.main,

          '&:hover': {
            borderColor: green.dark,
            boxShadow: '1px 3px 0px #4A8481',
          },
        },

        containedSecondary: {
          borderColor: magenta.main,
          boxShadow: '1px 3px 0px #86244E',

          '&:hover': {
            borderColor: magenta.dark,
            boxShadow: '1px 3px 0px #86244E',
          },
        },

        outlined: {
          padding: '1rem',
          borderRadius: '0.5rem',
          borderColor: grey.lighter,
        },

        text: {
          minWidth: 0,
          padding: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },

      defaultProps: {
        color: 'neutral',
        disableRipple: true,
        variant: 'outlined',
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          lineHeight: '1.35rem',
          color: black.main,
          '&::placeholder': {
            color: black.light,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: white,
          borderRadius: '0.5rem',
          '&$focused $notchedOutline': {
            borderWidth: 1,
          },

          '&$disabled $notchedOutline': {
            borderColor: black.main,
          },

          '&$focused': {
            borderWidth: 1,

            '& $notchedOutline': {
              borderColor: black.main,
              borderWidth: 1,
            },
          },

          '&:hover $notchedOutline': {
            borderColor: black.main,
          },
        },

        input: {
          padding: '1rem',
        },

        notchedOutline: {
          borderColor: grey.main,
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        inputProps: { autoComplete: 'off' },
        fullWidth: true,
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.375rem',
          fontWeight: 800,
          color: green.main,
          marginBottom: '1rem',
          display: 'block',
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          minHeight: 1,
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          paddingTop: '1rem',
          fontFamily: 'Fraktion Mono',
        },
      },
    },

    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
  },
};

export default createTheme(base, themeOptions);
