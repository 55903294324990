import React, { FunctionComponent, useEffect, useState } from 'react';

export interface WhenMountedProps {}

const useMounted = () => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);
  return mounted;
};

const WhenMounted: FunctionComponent = ({ children }) => {
  const mounted = useMounted();

  useEffect(() => {
    /* @ts-ignore */
    window.initialTime = Date.now();
  }, []);

  return mounted ? <>{children}</> : null;
};
export default WhenMounted;

export const whenMounted =
  (Component: React.ComponentType) =>
  (props: any = {}) =>
    (
      <WhenMounted>
        <Component {...props} />
      </WhenMounted>
    );
